import { ParagraphEyeCatcherFragmentFragment } from '../../../generated/types';
import React from 'react';
import ImageEyeCatcherBase from '../../Common/ImageEyeCatcherBase';
import { Flex, Box, Img, Button } from '@chakra-ui/react';
import { HTMLText } from '../../Common/HTMLText';
import Link from 'next/link';

interface EyeCatcherProps {
  data: ParagraphEyeCatcherFragmentFragment;
}

const EyeCatcher: React.FC<EyeCatcherProps> = ({ data }) => {
  const justify = data.alignment === 'left' ? 'flex-start' : 'flex-end';

  const ContentImage = (
    <Box
      ml={data.alignment === 'left' ? 0 : 4}
      mr={data.alignment === 'left' ? 4 : 0}
    >
      <Img
        maxWidth="400px"
        width="100%"
        htmlWidth="815px"
        htmlHeight="408px"
        src={data.contentImage.inline.url}
      />
    </Box>
  );

  return (
    <ImageEyeCatcherBase image={data.image}>
      <Flex color="#fff" justifyContent={justify}>
        {data.alignment === 'left' && ContentImage}
        <Flex
          direction="column"
          alignItems={justify}
          textAlign={data.alignment === 'left' ? 'left' : 'right'}
        >
          {data.headline && <HTMLText text={data.headline} />}
          {data.body && <HTMLText text={data.body} />}
          {data.linkUrl && data.linkText ? (
            <Box marginTop={4}>
              <Link href={data.linkUrl}>
                <a>
                  <Button as="span">{data.linkText}</Button>
                </a>
              </Link>
            </Box>
          ) : null}
        </Flex>
        {data.alignment === 'right' && ContentImage}
      </Flex>
    </ImageEyeCatcherBase>
  );
};

export default EyeCatcher;
